import React, {
    useCallback,
    useEffect, useRef,
    useState
} from "react"
import { useApolloClient } from "@apollo/client";
import { DocumentNode } from "graphql";
import {  DocumentArrowDown16Regular } from "@fluentui/react-icons";

// Types
import { LogEntry } from "../@types/LogEntry";

// Context
import { useUserContext } from "../common/contexts/UserContext";
import { useAppContext } from "../common/contexts/AppContext";
import { useTwinContext } from "../common/contexts/TwinContext";
import { useSettingsContext } from "../common/contexts/SettingsContext";

// Data
import { QUERY_METRIC } from "../common/api/live/gql/queryMetric";

// Utils
import Config from "../common/Config";
import { exportCSV } from "../common/utils/exportCsv";
import { parseTwin } from "../common/utils/parseTwin";

// Components
import {
    DialogTrigger,
    Button,
} from "@fluentui/react-components";
import Frame from "../components/Frame/Frame"
import PreFlightReport from "../components/PreFlightReport";
import { The3DScene } from "../components/The3DScene";
import TLDialog from "../components/elements/TLDialog";
import TLSpinnerPage from "../components/elements/TLSpinnerPage";
import Settings from "../components/Settings";

interface Props {
    className?: string
}
interface FetchDataResult {
    model: any;
}

const MainPage: React.FC<Props> = ({ className }) => {

    const { twinId, userPrefs } = useUserContext();
    const { settings, setMetrics } = useSettingsContext();
    const { showSettings, setShowSettings } = useAppContext();
    const { setTwin } = useTwinContext();
    const apolloClient = useApolloClient();
    const twinPoller = useRef<ReturnType<typeof setInterval> | number | null>(null);

    const [showPreFlightReport, setShowPreFlightReport] = useState<boolean>(false);
    const [preFlightReport, setPreFlightReport] = useState<LogEntry[]>([]);

    const [loadingBanterRunning, setLoadingBanterRunning] = useState(false);
    const [allModelsLoaded, setAllModelsLoaded] = useState(false);

    const onAllModelsLoaded = useCallback(() => setAllModelsLoaded(true), [setAllModelsLoaded])
    /*
     * Triggered when the Main Page loads
     * Fetches Model and Tags for the Twin/Site
     * Passes both these datasets into the TwinParser which preps the data for the 2D and 3D scene
     * 
    */
    useEffect(() => {

        if (userPrefs) {

            const fetchModel = async (query: DocumentNode, id: String): Promise<undefined | FetchDataResult> => {
                try {
                  
                    const { data: model } = await apolloClient.query({
                        query: query,
                        variables: {
                            "filter": {
                                "bID": {
                                    "eq": id
                                }
                            }
                        }
                    })
                    return { model }
                } catch (error) {
                    console.error('Error:', error);
                }
            };

            const fetchMetrics = async (): Promise<undefined | any> => {
                try {
                    const { data } = await apolloClient.query({
                        query: QUERY_METRIC,
                    })
                    return data.queryMetric
                } catch (error) {
                    console.error('Error:', error);
                }
            }

            const loadTwin = () => fetchModel(userPrefs.modelQuery, userPrefs.modelQueryValue).then((data: undefined | FetchDataResult) => {

                // Parse/set the Twin using the model
                let result 
                if (settings && settings.organisation) {
                    result = parseTwin(settings.organisation, data?.model.queryTwinEntity[0], Config.preflightReport === 'true', settings?.apolloExplorerURI, settings?.entities)
                }

                if (!result) {
                    throw new Error("Unable to parse twin model");
                }

                if (result.preFlightReport.length > 0) {
                    setPreFlightReport(result.preFlightReport)
                    setShowPreFlightReport(result.preFlightReport.length > 0)
                }

                setTwin(result.twin)
            })
            
            if (!allModelsLoaded) setLoadingBanterRunning(true) // only do this for the first load
            loadTwin()
            

            // Add artificial delay to initial load to allow loading messages to display
            const delay = Config.initialOnloadDelay ? parseInt(Config.initialOnloadDelay) : 6000
            setTimeout(() => {
                setLoadingBanterRunning(false)
                //setAllModelsLoaded(true) // Uncomment this line if you disable the 3D scene
            }, delay);

            if (twinPoller.current) {
                clearInterval(twinPoller.current);
            }

            if (settings?.pollTwin) {
            twinPoller.current = setInterval(loadTwin,30000) // twice per minute
            } else {
                twinPoller.current = 1
            }
            
            // e.g. temperature, speed, countEntity, windSpeed
            fetchMetrics().then((data) => {
                if (data) {
                    setMetrics(data)
                }
            })

        }

        return () => {
            if (twinPoller.current) {
                clearInterval(twinPoller.current)
                twinPoller.current = null
            }
        }
    }, [
        apolloClient,
        settings?.apolloExplorerURI,
        settings?.entities,
        settings?.pollTwin,
        twinId,
        allModelsLoaded,
        setMetrics,
        setTwin,
        userPrefs,
    ])

    if (showSettings) {
        return (
            <div className="flex mt-20 h-screen">
                <div>
                    <Settings onClose={() => setShowSettings(false)} />
                </div>
            </div>
        )
    }

    return (
        <div className="relative">
            {!showPreFlightReport && (loadingBanterRunning || !allModelsLoaded) &&
                <div className="h-screen w-screen bg-slate-50 flex items-center justify-center absolute z-10">
                    <div>
                        <TLSpinnerPage />
                    </div>
                </div>
            }
            {showPreFlightReport ?
                <TLDialog
                    defaultOpen={true}
                    title="Preflight Report"
                    dialogContent={<PreFlightReport
                    data={preFlightReport} />}
                    dialogActions={
                        <>
                            <DialogTrigger>
                                <Button appearance="secondary" onClick={() => {
                                    exportCSV(preFlightReport, 'preflight-report.csv')
                                    setShowPreFlightReport(false)
                                }}>
                                    <DocumentArrowDown16Regular />
                                    Export
                                </Button>
                            </DialogTrigger>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="primary" onClick={() => setShowPreFlightReport(false)}>Dismiss</Button>
                            </DialogTrigger>
                        </>

                    }
                />
                :
                <div className="w-full py-8 relative">
                    <The3DScene className="absolute z-0 w-full h-screen" onAllModelsLoaded={onAllModelsLoaded} allModelsLoaded={allModelsLoaded} />
                    <Frame />
                </div>
                }
        </div>
    )
}

export default MainPage
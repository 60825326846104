import { useEffect, useMemo, useRef, useState } from "react"

// Types
import { Message } from "../../@types/Message"

// Context
import { useUserContext } from "../../common/contexts/UserContext"

// Utils
import { formatCopilotMessage } from "../../common/utils/formatCopilotMessage"

// Components
import { ReactComponent as CopilotColorIcon } from "../../common/svg/copilot-color-icon.svg"
import { Avatar } from "@fluentui/react-components"

interface Props {
    className?: string
    messages: Message[]
    awaitingMessage: boolean
    addMessage: (message: Message) => void
    clearMessages: () => void
}

const CopilotConversation = ({
    className,
    messages,
    awaitingMessage,
    addMessage,
    clearMessages
}: Props) => {

    const [loading, setLoading] = useState<boolean>(false)
    const { user } = useUserContext()
    const copilotConversationRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setLoading(awaitingMessage)
    }, [awaitingMessage])

    const displayName = useMemo(() => {
        let displayName
        if (user?.firstName && user?.lastName) {
            displayName = `${user.firstName} ${user.lastName}`
        } else if (user?.organisation?.name) {
            displayName = user.organisation.name
        }
        return displayName
    }, [user]);


    useEffect(() => {
        scrollToBottom()
    }, [loading, messages])


    const scrollToBottom = () => {
        if (copilotConversationRef.current) {
            copilotConversationRef.current.scrollTop = copilotConversationRef.current.scrollHeight;
        }
    }

    return (
        <>
            {(messages && messages.length > 0) ?
                <div className={className} ref={copilotConversationRef}>
                    {messages.map((msg, i) => {
                        return (
                            <div key={i} className="message">
                                {!msg.response ?
                                    <div className="avatar mr-2">
                                        <Avatar size={20} image={{ src: user?.organisation?.logo ? user?.organisation?.logo : undefined }} name={displayName} aria-label={displayName} />
                                    </div>
                                    : null}
                                <div className={`chat-bubble ${msg.response === false || msg.chart ? `text-left` : `text-right ml-auto`}`}>
                                    <div className="response" dangerouslySetInnerHTML={{ __html: formatCopilotMessage(msg.content) }}></div>
                                    {msg.prompts && msg.prompts.length > 0 ?
                                        <>
                                            {msg.prompts?.map((p, i) => {
                                                return (
                                                    <div key={i} className="prompt">
                                                        <button
                                                            onClick={() => {
                                                                setLoading(true)
                                                                addMessage({ content: p.title, response: false, prompts: [], triggerPrompt: true })
                                                            }}
                                                            className={`chat-bubble text-left`}
                                                            dangerouslySetInnerHTML={{ __html: p.title }}>
                                                        </button>
                                                    </div>
                                                )
                                            })}
                                        </>
                                        : null}
                                    {msg.chart ?
                                        <>
                                            {msg.chart}
                                        </>
                                    : null}
                                </div>
                                {msg.response ?
                                    <div className="avatar ml-2">
                                        <CopilotColorIcon />
                                    </div>
                                    : null}
                            </div>
                        )
                    })}

                    {loading ?
                        <div className="message">
                            <div className="chat-bubble text-right ml-auto">
                                Thinking...
                            </div>
                            <div className="avatar ml-2">
                                <CopilotColorIcon />
                            </div>
                        </div>
                        : null}
                </div>
                : null}
        </>
    )
}

export default CopilotConversation
// Types
import { DataMode } from "../../../@types/DataMode";
import { Environment } from "../../../@types/Settings/Environment";
import { IndicatorColor } from "../../../@types/Settings/IndicatorColor";
import { LabelDisplayOn } from "../../../@types/Settings/LabelDisplayOn";
import { Settings } from "../../../@types/Settings/Settings";
import { TwinEntityType } from "../../../@types/TwinEntityType";

// Data
import { getAgeData } from "../../api/analytics/getAgeData";
import { getGenderData } from "../../api/analytics/getGenderData";
import { getMembershipData } from "../../api/analytics/getMembershipData";

// Data / Gym Asset Label Configs
import { LabelSetGymAssetTimeSeriesBrief } from "./labelSets/timeseries/LabelSetGymAssetTimeSeriesBrief";
import { LabelSetGymAssetTimeSeriesFull } from "./labelSets/timeseries/LabelSetGymAssetTimeSeriesFull";
import { LabelSetGymAssetLiveBrief } from "./labelSets/live/LabelSetGymAssetLiveBrief";
import { LabelSetGymAssetLiveFull } from "./labelSets/live/LabelSetGymAssetLiveFull";

// Data / Gym Category Label Configs
import { LabelSetGymCategoryTimeSeriesBrief } from "./labelSets/timeseries/LabelSetGymCategoryTimeSeriesBrief";
import { LabelSetGymCategoryTimeSeriesFull } from "./labelSets/timeseries/LabelSetGymCategoryTimeSeriesFull";
import { LabelSetGymCategoryLiveBrief } from "./labelSets/live/LabelSetGymCategoryLiveBrief";
import { LabelSetGymCategoryLiveFull } from "./labelSets/live/LabelSetGymCategoryLiveFull";

// Data / Gym Zone Label Configs
import { LabelSetGymZoneTimeSeriesBrief } from "./labelSets/timeseries/LabelSetGymZoneTimeSeriesBrief";
import { LabelSetGymZoneTimeSeriesFull } from "./labelSets/timeseries/LabelSetGymZoneTimeSeriesFull";
import { LabelSetGymZoneLiveBrief } from "./labelSets/live/LabelSetGymZoneLiveBrief";
import { LabelSetGymZoneLiveFull } from "./labelSets/live/LabelSetGymZoneLiveFull";

export const newportSitePrefData: Settings = {
    organisation: 'Newport Live',
    heroMetrics: [
        {
            metric: 'usage',
            entity: 'AVG',
            timeline: 'AVG',
            calendar: 'AVG'
        },
    ],
    liveDataInterval: '15s',
    timeZone: 'Europe/London',
    apolloExplorerURI: 'Twinlabs-Dev-b37eu',
    startLive: true,
    environment: {
        environment: Environment.INDOOR,
        hdrPath: '/images/hdr/indoor.hdr',
    },
    labels: [
        {
            twinEntityType: TwinEntityType.ASSET,
            labelSets: [
                {
                    brief: LabelSetGymAssetTimeSeriesBrief,
                    full: LabelSetGymAssetTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetGymAssetLiveBrief,
                    full: LabelSetGymAssetLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
        {
            twinEntityType: TwinEntityType.CATEGORY,
            labelSets: [
                {
                    brief: LabelSetGymCategoryTimeSeriesBrief,
                    full: LabelSetGymCategoryTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetGymCategoryLiveBrief,
                    full: LabelSetGymCategoryLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
        // IMPORANT: None of the data in model has ZONE or CATEGORY Digital Twin Entity types
        // Need to change ZONE to AREA in order to show labels within Accordion
        {
            twinEntityType: TwinEntityType.ZONE,
            labelSets: [
                {
                    brief: LabelSetGymZoneTimeSeriesBrief,
                    full: LabelSetGymZoneTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetGymZoneLiveBrief,
                    full: LabelSetGymZoneLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
    ],
    entities: {
        id: 'entities',
        children: [
            {
                id: '0x75e5',
                icon: 'Dumbbell20Regular',
            },
        ]
    },
    analytics: {
        id: 'analytics',
        introPrompts: [
            {
                title: "Analyze the age group",
                prompt: "Analyze the age group",
            },
            {
                title: "Analyze the membership type",
                prompt: "Analyze the membership type",
            },
            {
                title: "Analyze the gender data",
                prompt: "Analyze the gender data",
            },
        ]
    },
    timeline: {
        id: 'timeline',
        visible: true,
        open: true,
        order: 1,
        interval: '15m',
        hourRange: {
            startTime: '06:00',
            finishTime: '22:00',
        }
    },
    calendar: {
        id: 'calendar',
        visible: true,
        open: true,
        icon: '',
        order: 2,
        hourRange: {
            startTime: '06:00',
            finishTime: '22:00',
        }
    },
    rangeMapping: [
        {
            color: IndicatorColor.ZERO,
            op: `value === 0`,
        },
        {
            color: IndicatorColor.VERY_LOW,
            op: `value >= 1 && value <= 10`,
        },
        {
            color: IndicatorColor.LOW,
            op: `value >= 11 && value <= 20`,
        },
        {
            color: IndicatorColor.MEDIUM_LOW,
            op: `value >= 21 && value <= 30`,
        },
        {
            color: IndicatorColor.MEDIUM,
            op: `value >= 31 && value <= 40`,
        },
        {
            color: IndicatorColor.MEDIUM_HIGH,
            op: `value >= 41 && value <= 50`,
        },
        {
            color: IndicatorColor.HIGH,
            op: `value >= 51 && value <= 75`,
        },
        {
            color: IndicatorColor.VERY_HIGH,
            op: `value >= 76 && value <= 100`,
        },
        {
            color: IndicatorColor.EXTREMELY_HIGH,
            op: `value > 100`,
        }
    ],
    leftFieldMessages: [
        "Pumping iron",
        "Creating a 3D gym that Arnie's avatar would want to work out in",
        "Pausing for selfie in the gym mirror",
        "Listening to Eye of the Tiger on repeat",
    ],
    pollTwin: false,
}
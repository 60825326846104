/*
	* Site Component
    * 
	* The Site component uses the twin model coordinate system to determine what context the twin data should be configured to,
    * in order to adopt the correct world space map position and orientation within the Mapbox context.
    * 
    * the coordinate systems currently in use are "COORD_LOCAL" ( Newport ) | "COORD_WGS84" ( Movico ).
*/

import React, { ReactNode } from "react";
import { InternalSite } from "./InternalSite";
import { ExternalSite } from "./ExternalSite";
import { TwinEntity } from "../../../../generated/devGraphql";

interface Props {
	entity: TwinEntity;
	children?: ReactNode;
}

/**
	* Site Component
    * 
	* The Site component uses the twin model coordinate system to determine what context the twin data should be configured to,
    * in order to adopt the correct world space map position and orientation within the Mapbox context.
    * 
    * the coordinate systems currently in use are "COORD_LOCAL" ( Newport ) | "COORD_WGS84" ( Movico ).
*/

const Site = ({ entity, children }: Props) => {

    if (entity.coordinateSystem === 'COORD_LOCAL') {
        // TODO is reducing this really necessary? 
        const coordinates = entity.boundaries?.polygons.map(polygon => polygon.coordinates).reduce((a, b, _, array) => a.concat(b));
        const mapOrigin = entity.mapOrigin;
        const orientation = entity.rotationY ? entity.rotationY - 10.3 : 0; // hack, because it's changed for Newport and I'm not sure why

    if (coordinates && mapOrigin) {
        return  <InternalSite 
                    coordinates={coordinates} 
                    mapOrigin={mapOrigin}
                    orientation={orientation}
                    >
                    {children}
                </InternalSite>
    } else {
        alert('COORD_LOCAL twin is malformed')
        return <></>
    }

        
    } else {
        return  <ExternalSite >
                    {children}
                </ExternalSite>
    }

            

     
    }

export { Site };

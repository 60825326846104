/*
 * avgHeadcountByDTE (DTE = Digital Twin Entity)
 * This is a wrapper for the timeSeriesRangeQuery function
 * Returns Avg Headcount Hero Metric filtered by Organisation and DTE
 * 
*/

// Utils
import { timeSeriesRangeQuery } from "./timeSeriesRangeQuery";

export const avgHeadcountByDTE = async (
    startDateTime: string,
    endDateTime: string,
    step: string = "15m",
    organisation: string,
    digitalTwinEntity?: string,
    offset?: string) => {
    
    let filterBy = `metric='countEntity'`
    let offsetBy

    if (organisation) {
        filterBy = filterBy + `,organisation='${organisation}'`
    }

    if (digitalTwinEntity) {
        filterBy = filterBy + `,digitalTwinEntity='${digitalTwinEntity}'`
    }

    if (offset) {
        offsetBy = `offset ${offset}`
    }

    const query = `avg by(digitalTwinEntity,digitalTwinEntityPath, metric) (avg_over_time(metricvalue{${filterBy}}[${step}] ${offsetBy}))`
    const result = await timeSeriesRangeQuery(query, startDateTime, endDateTime, step)
    return result
}
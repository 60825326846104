import { RefObject, Suspense, useEffect, useMemo, useRef } from "react";
import { StatsGl } from '@react-three/drei'
import {  
	LightsAndShadows,
} from "./SceneLighting";
import { coordsToVector3, useMap } from "react-three-map";
import { DebugThreeGlInfo } from "./helpers/DebugThreeGlInfo";

// Types
import { Twin } from "../../@types/Twin";
import Config from "../../common/Config";
import { PostProcesses } from "./postprocessing/PostProcesses";
import { TwinEntity3D } from "./TwinEntity3D";
import { degreesToRadians, getCentre, radiansToDegrees } from "./utils/geometry";
import { stringToTwinEntityType } from "../../common/utils/stringToTwinEntityType";
import { TwinEntityType } from "../../@types/TwinEntityType";

const radiusInMiles = 0.05;
const radiusInMeters = radiusInMiles * 1609.34;
const earthRadius = 6378137;
const latRadian = radiusInMeters / earthRadius;



// Interface props for ThreeScene component
interface Props {
	twin: Twin;
	infoRef: RefObject<HTMLDivElement>;
	onAllModelsLoaded: () => void
	mapOrigin: { latitude: number, longitude: number }
}


/**
	* ThreeScene
	*
	* The ThreeScene component is the main React-Three-Fiber 3D parent container for all procedural 3D assets generated from graph data.
	* The component contains and integrates the required features for InternalSite, ExternalSite, CustomEnvironment ( for environmental lighting )
	* and post-processing effects ( to enhance visual aesthetic ). The ThreeScene can be Extended to be the parent of any twin data context to visualize 3D elements.
 */

const ThreeScene = ({ twin, mapOrigin, infoRef, onAllModelsLoaded }: Props) => {

	
	// poll a dynamically expanded map of models pending loading
	const loadingListRef =  useRef<Map<string, boolean>>(new Map<string, boolean>())

    useEffect(() => {

        const checkIfAllLoaded = () => {
            let allLoaded = true;

            // if the list is empty this will just leave allloaded as true
            loadingListRef.current.forEach((value, _) => {
                    allLoaded = allLoaded && value // a single false value will cause it to stay false till the end
                })
            
                
            if (allLoaded) {
                onAllModelsLoaded()
                return true
            } else {
                return false
            }
    
        }

		setTimeout(() => {
			const checker = setInterval(() => {
				const allLoaded = checkIfAllLoaded()
				if (allLoaded) {
					clearInterval(checker)
				}
			}, 
			
			100)}, // time between checks
		
		100) // time to allow list to be populated

		return () => {
			loadingListRef.current = new Map<string, boolean>()
		}
		
       
    },[onAllModelsLoaded])

	
	// Access the map and its container element from React-Three-Map.
	const map = useMap();
	const mapDiv: HTMLDivElement | null = useMemo(()=>{
		return map._canvas.parentElement as HTMLDivElement;
	}, [map]) 

	const useIndoorEnv = twin.model.organisation?.name === "Newport Live";

    const bounds = useRef<[[number,number],[number,number]] | null>(null)

    useEffect(()=>{

        if (!bounds.current && map) {
			
			let centre = { latitude: 0, longitude: 0 }
			
			if (stringToTwinEntityType(twin.model.type.name) === TwinEntityType.SITE) {
				if (twin.model.boundaries?.polygons) {
					centre = getCentre(twin.model.boundaries.polygons)
				}
			}
					
			if (twin.model.coordinateSystem === "COORD_WGS84") {
				const localCoords = coordsToVector3(centre, mapOrigin)
				centre = { latitude: localCoords[0], longitude: localCoords[2] }
			} else {
				centre.latitude /= 1000
				centre.longitude /= 1000
			}

			const lat = mapOrigin.latitude + centre.latitude
			const long = mapOrigin.longitude + centre.longitude
			
			const lngRadian = radiusInMeters / (earthRadius * Math.cos(degreesToRadians(lat)));

			bounds.current = [
				[
					long - (radiansToDegrees(lngRadian)), //west
					lat - (radiansToDegrees(latRadian)) // south
				],
				[
					long + (radiansToDegrees(lngRadian)), //east
					lat + (radiansToDegrees(latRadian)) //north
				]
			]

			const cameraInitConfig = twin.model.organisation?.name === "Newport Live" ? {
				zoom: 19.5,
				bearing: 150,
				pitch: 60
			} : {
				zoom: 19,
				bearing: 340,
				pitch: 70
			}

			if (map) {
				map.setMaxBounds(bounds.current)
				map.setZoom(cameraInitConfig.zoom)
				map.setBearing(cameraInitConfig.bearing)
				map.setPitch(cameraInitConfig.pitch)
				map.setCenter([long, lat])
			}
		
        }
    },[map, mapOrigin, twin.model])

	return (
				<Suspense>
					{loadingListRef.current &&
						<TwinEntity3D
						entity={twin.model}
						mapDiv={mapDiv}
						loadingList={loadingListRef.current}
						depth={0} lineage={[]}
						mapOrigin={mapOrigin}
					/>
					}
					<LightsAndShadows indoor={useIndoorEnv}/>
					<PostProcesses/>
					{Config.appProfile ==='DEBUG' &&
					<>
					<StatsGl/>
					<DebugThreeGlInfo infoRef={infoRef}/>
					</>
					}
				</Suspense>
	);
};

export { ThreeScene };
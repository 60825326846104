import { useEffect, useState } from "react"

// Context
import { useSettingsContext } from "../common/contexts/SettingsContext"

// Types
import { SelectedCellValue } from "../@types/SelectedCellValue"

// Utils
import { getIndicatorColor } from "../common/utils/getIndicatorColor"

// Components
import Indicator from "./Indicator";
import { roundToOneDecimalPlace } from "../common/utils/roundToOneDecimalPlace";


interface Props {
    className?: string
    selectedCellValues: SelectedCellValue[]
}

const CalendarSelectionOverlay = ({ className, selectedCellValues }: Props) => {

    const { settings } = useSettingsContext()
    const [indicatorColor, setIndicatorColor] = useState<string | null>(null)
    const [indicatorValue, setIndicatorValue] = useState<string | null>(null)

    useEffect(() => {

            let totalCount = 0
            let numberOfCells = 0

            selectedCellValues.forEach((scv) => {
                totalCount = totalCount + scv.cellValue
                numberOfCells = numberOfCells + 1
            })

            const value = totalCount / numberOfCells
            const color = getIndicatorColor(value, settings?.rangeMapping, true)

            setIndicatorValue(`${roundToOneDecimalPlace(value).toFixed(1)}%`)
            setIndicatorColor(color)

    },
        [
            selectedCellValues,
            settings?.rangeMapping
        ])

    return (
        <>
        {indicatorValue && indicatorColor ?
        <div className={className}>
            <div className="selection-overlay">
                <div className="selection-overlay-value">
                    <Indicator backgroundColor={indicatorColor} value={indicatorValue} />
                </div>
                <div className="selection-overlay-arrow"></div>
            </div>
        </div> : null}
        </>
    )
}

export default CalendarSelectionOverlay
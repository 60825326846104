import { TwinEntity } from "../../../../generated/devGraphql";
import { Vector3 } from "three";
import { Station } from "./Station";

interface Props {
	entity: TwinEntity,
	lineage: TwinEntity[],
	mapDiv: HTMLDivElement;
	depth: number;
}

const STATION_WIDTH = 3;
const OPERATOR_LENGTH = 1;
const GAP_RATIO = 0.2;

export const EventSpace = ({ entity, lineage, mapDiv }: Props) => {
	
	return 	<group>
				{entity.children?.map(((child, idx) => {

					const operatorNumber = child.children?.length ?? 1
					const boxLength = operatorNumber * OPERATOR_LENGTH * (1 + GAP_RATIO)
					return <Station
						        key={idx}
								entity={child}
								boxWidth={STATION_WIDTH}
								boxLength={boxLength}
								position={new Vector3(idx * STATION_WIDTH * (1 + GAP_RATIO), 0, boxLength / 2)}
								lineage={[...lineage, entity]}
								mapDiv={mapDiv}
								gapRatio={GAP_RATIO}
								operatorLength={OPERATOR_LENGTH}
							/>
				}))
				}
			</group>
};



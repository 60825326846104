import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useThree } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import { Box3, Camera, Object3D, Vector3 } from 'three';
import { clamp } from 'three/src/math/MathUtils';

// Types

import { Entity } from '../../../../@types/Entity';

import { LabelMode } from '../../../../@types/LabelMode';
import { TwinEntityType } from '../../../../@types/TwinEntityType';

// Contexts
import {useDataContext} from '../../../../common/contexts/DataContext';

// Components

import TLCard  from '../../../elements/TLCard'



interface Props {
	entity: Entity;
    visible: boolean;
    boundingBox: Box3;
    entityType: TwinEntityType;
    labelMode: LabelMode;
}

const MIN_FONTSIZE = 8
const MAX_FONTSIZE = 14
const MAX_MINWIDTH = 201

type LabelStyles = {
    fontSize: number,
    minWidth: number,
}

const updateLabelStyles = (
    styles: LabelStyles,
    objectRef: MutableRefObject<Object3D | null>,
    camera: Camera,
    setStyles: (styles: LabelStyles) => void

) => {
    const labelPostion = new Vector3()
    objectRef.current?.getWorldPosition(labelPostion)
    const fontSize = clamp(Math.round(400 / new Vector3().subVectors(labelPostion, camera.position).length()), MIN_FONTSIZE, MAX_FONTSIZE)
    const minWidth = MAX_MINWIDTH - 20*(MAX_FONTSIZE - fontSize)
    if ((styles.fontSize !== fontSize) || (styles.minWidth !== minWidth)) {
        setStyles({...styles,
            fontSize,
            minWidth
        })
    } 
}

// need to fix labels 
export const OperatorLabel3D = ({ entity, visible, boundingBox, entityType, labelMode }: Props) => {

    const [styles, setStyles] = useState({fontSize: 14, minWidth: MAX_MINWIDTH })
    const objectRef = useRef<Object3D | null>(null)
    const [eventListenersAdded, setEventListenersAdded] = useState(false)

    const camera  = useThree(state => state.camera)

   

    useEffect(() => {
        
        if (!eventListenersAdded) {

            window.addEventListener('pointermove', (_) => {
                updateLabelStyles(styles, objectRef, camera, setStyles)
            })
    
            window.addEventListener('wheel', (_) => {
                updateLabelStyles(styles, objectRef, camera, setStyles)
            })

            setEventListenersAdded(true)
        }
       
        return () => {
            window.removeEventListener('pointermove', (_) => {
            updateLabelStyles(styles, objectRef, camera, setStyles)
            
        })
            window.removeEventListener('wheel', (_) => {
            updateLabelStyles(styles, objectRef, camera, setStyles)
        })
    }}, [eventListenersAdded, setEventListenersAdded, camera.position, camera, styles])

    // Access data-related functions and settings from Data Context

    const { data } = useDataContext()

    const matchingTicketMetrics = data.processed ? Object.values(data.processed)
        .flatMap((dataValues) => dataValues
            .filter((dataValue) => dataValue.entityId === entity.id)) : []





    if (visible) {
    updateLabelStyles(styles, objectRef, camera, setStyles)
    }

    return (
        <object3D ref={objectRef} position={new Vector3(boundingBox.min.x, boundingBox.min.y, -boundingBox.max.z)}>
            <Html
                key={'lbl'}
                center
                className='whitespace-nowrap pointer-events-none'
                visible={visible}
            >
                <div style={{ display: visible ? "block" : "none", ...styles }}>
                    <TLCard size={"small"}>
                        <div >
                                <div className='flex font-bold'>
                                    <span style={{ flex: 1}}>
                                    {entity.name}
                                    </span>
                                    <div style={{ paddingLeft: '8px' }}>

                                    </div>
                                </div>
                            <ul>
                                {matchingTicketMetrics.map((metric, i) => {
                                    return (
                                        <li key={i}><span>{metric.metric.name}</span>:  {metric.value} {metric.metric.unit}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </TLCard>
                </div>
            </Html>
        </object3D> 
    )
}


export type LoginData = {
  aiKey: string
  aiUrl:  string
  graphqlToken: string
  mapboxToken:  string
  modelUrlKey:  string
  organisation: string
  userName:     string
}

export type RawLoginData = {
  AIKey: string,
  AIUrl: string,
  GraphqlToken: string
  MapboxToken:  string
  ModelUrlKey:  string
  Organisation: string
  UserName:     string
}

export function camelCaseRawLoginData(data: RawLoginData): LoginData {
return {
  aiKey: data.AIKey,
  aiUrl: data.AIUrl,
  graphqlToken: data.GraphqlToken,
  mapboxToken: data.MapboxToken,
  modelUrlKey: data.ModelUrlKey,
  organisation: data.Organisation,
  userName: data.UserName
}
}

type SuccessfulLoginResult = {
  success: true
  data:  RawLoginData
}

type UnSuccessfulLoginResult = {
  statusCode?: number
  success: false
  data:  {
    error: string
  }
}

export type LoginResult = SuccessfulLoginResult | UnSuccessfulLoginResult
import React, { useEffect, useState } from "react";
import { DataUsage24Filled, DataUsage24Regular, WebAsset24Filled, WebAsset24Regular } from "@fluentui/react-icons";
import './Frame/Frame.css';
// Types
import { TabPanel } from "../@types/TabPanel";

// Components
import AnalyticsCopilot from "./AnalyticsCopilot";
import Assets from "./Accordion2/Assets";
import TLCard from "./elements/TLCard";
import TLSwitcher from "./elements/TLSwitcher";
import CustomScrollbar from "./CustomScrollbar/CustomScrollbar";

interface Props {
    className?: string;
    liveMode: boolean;
}

interface TabOption {
    inactiveIcon: JSX.Element;
    activeIcon: JSX.Element;
    value: string;
    text: string;
}

const AssetsAndAnalytics: React.FC<Props> = ({ className, liveMode }) => {
    const [tabPanel, setTabPanel] = useState<TabPanel>(TabPanel.ASSETS);
    const [tabOptions, setTabOptions] = useState<TabOption[]>([]);

    useEffect(() => {
        const tabOpts = [
            {
                inactiveIcon: <WebAsset24Regular />,
                activeIcon: <WebAsset24Filled />,
                value: TabPanel.ASSETS.toString(),
                text: "Assets",
            },
        ];
        if (!liveMode) {
            tabOpts.push({
                inactiveIcon: <DataUsage24Regular />,
                activeIcon: <DataUsage24Filled />,
                value: TabPanel.ANALYTICS.toString(),
                text: "Analytics",
            });
        }

        if (liveMode) {
            setTabPanel(TabPanel.ASSETS);
        }

        setTabOptions(tabOpts)

    }, [liveMode, tabPanel])



    const ExpandPanel = () => {
        console.log(`Expand Panel`)
    }

    return (
        <div className={className}>
            <div className="panel-header left-panel-header">
                <TLCard className="tab-list" size="small">
                    <TLSwitcher
                        size="small"
                        selectedValue={tabPanel.toString()}
                        options={tabOptions}
                        setValue={setTabPanel}
                    />
                </TLCard>
            </div>

            
                {tabPanel === TabPanel.ASSETS && <CustomScrollbar className="left-scrollable-container" maxHeight={"calc(100vh - 210px)"}><Assets /></CustomScrollbar>}
                {tabPanel === TabPanel.ANALYTICS && <AnalyticsCopilot className="analytics-copilot" expandPanel={ExpandPanel} />}
        </div>
    );
};

export default AssetsAndAnalytics;

// Types
import { DataMode } from "../../../@types/DataMode";
import { Environment } from "../../../@types/Settings/Environment";
import { IndicatorColor } from "../../../@types/Settings/IndicatorColor";
import { LabelDisplayOn } from "../../../@types/Settings/LabelDisplayOn";
import { Settings } from "../../../@types/Settings/Settings";
import { TwinEntityType } from "../../../@types/TwinEntityType";

// Data / Event Area Label Configs
import { LabelSetEventAreaLiveBrief } from "./labelSets/live/LabelSetEventAreaLiveBrief";
import { LabelSetEventAreaLiveFull } from "./labelSets/live/LabelSetEventAreaLiveFull";
import { LabelSetEventAreaTimeSeriesBrief } from "./labelSets/timeseries/LabelSetEventAreaTimeSeriesBrief";
import { LabelSetEventAreaTimeSeriesFull } from "./labelSets/timeseries/LabelSetEventAreaTimeSeriesFull";

// Data / Event Ticket Type Label Configs
import { LabelSetEventTicketLiveBrief } from "./labelSets/live/LabelSetEventTicketLiveBrief";
import { LabelSetEventTicketLiveFull } from "./labelSets/live/LabelSetEventTicketLiveFull";
import { LabelSetEventTicketTimeSeriesBrief } from "./labelSets/timeseries/LabelSetEventTicketTimeSeriesBrief";
import { LabelSetEventTicketTimeSeriesFull } from "./labelSets/timeseries/LabelSetEventTicketTimeSeriesFull";

// Data / Event Chute Label Configs
import { LabelSetEventChuteLiveBrief } from "./labelSets/live/LabelSetEventChuteLiveBrief";
import { LabelSetEventChuteLiveFull } from "./labelSets/live/LabelSetEventChuteLiveFull";
import { LabelSetEventChuteTimeSeriesBrief } from "./labelSets/timeseries/LabelSetEventChuteTimeSeriesBrief";
import { LabelSetEventChuteTimeSeriesFull } from "./labelSets/timeseries/LabelSetEventChuteTimeSeriesFull";

export const howlerSitePrefData: Settings = {
    organisation: 'Howler',
    heroMetrics: [
        {
            metric: 'transactionRate',
            entity: 'AVG',
            timeline: 'AVG',
            calendar: 'AVG'
        },
    ],
    liveDataInterval: '15s',
    timeZone: 'Europe/London',
    apolloExplorerURI: 'Howler',
    startLive: true,
    environment: {
        environment: Environment.INDOOR,
        hdrPath: '/images/hdr/indoor.hdr',
    },
    labels: [
        {
            twinEntityType: TwinEntityType.AREA,
            labelSets: [
                {
                    brief: LabelSetEventAreaTimeSeriesBrief,
                    full: LabelSetEventAreaTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetEventAreaLiveBrief,
                    full: LabelSetEventAreaLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
        {
            twinEntityType: TwinEntityType.TICKET,
            labelSets: [
                {
                    brief: LabelSetEventTicketTimeSeriesBrief,
                    full: LabelSetEventTicketTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetEventTicketLiveBrief,
                    full: LabelSetEventTicketLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
        {
            twinEntityType: TwinEntityType.CHUTE,
            labelSets: [
                {
                    brief: LabelSetEventChuteTimeSeriesBrief,
                    full: LabelSetEventChuteTimeSeriesFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.TIME_SERIES
                },
                {
                    brief: LabelSetEventChuteLiveBrief,
                    full: LabelSetEventChuteLiveFull,
                    displayOn: LabelDisplayOn.INFO_CARD,
                    dataMode: DataMode.LIVE
                },
            ]
        },
    ],
    entities: {
        id: 'entities',
        children: []
    },
    analytics: {
        id: 'analytics',
        introPrompts: [
            {
                title: "Which gate has the lowest check-in rate?",
                prompt: "Which gate has the lowest check-in rate?"
            },
            {
                title: "Which operators are running low on battery?",
                prompt: "Which operators are running low on battery?"
            },
            {
                title: "Who is our fastest operator right now?",
                prompt: "Who is our fastest operator right now?"
            },
        ]
    },
    timeline: {
        id: 'timeline',
        visible: true,
        open: true,
        order: 1,
        interval: '15m',
    },
    calendar: {
        id: 'calendar',
        visible: false,
        icon: '',
        order: 2,
        hourRange: {
            startTime: '06:00',
            finishTime: '22:00',
        }
    },
    rangeMapping: [
        {
            color: IndicatorColor.ZERO,
            op: `value === 0`,
        },
        {
            color: IndicatorColor.VERY_LOW,
            op: `value >= 1 && value <= 10`,
        },
        {
            color: IndicatorColor.LOW,
            op: `value >= 11 && value <= 20`,
        },
        {
            color: IndicatorColor.MEDIUM_LOW,
            op: `value >= 21 && value <= 30`,
        },
        {
            color: IndicatorColor.MEDIUM,
            op: `value >= 31 && value <= 40`,
        },
        {
            color: IndicatorColor.MEDIUM_HIGH,
            op: `value >= 41 && value <= 50`,
        },
        {
            color: IndicatorColor.HIGH,
            op: `value >= 51 && value <= 75`,
        },
        {
            color: IndicatorColor.VERY_HIGH,
            op: `value >= 76 && value <= 100`,
        },
        {
            color: IndicatorColor.EXTREMELY_HIGH,
            op: `value > 100`,
        }
    ],
    leftFieldMessages: [
        "Fixing a flat tyre",
        "Checking the bike suspension",
        "Shaving my legs to improve aerodynamics",
        `Using The "N+1" Rule to justify my latest bike purchase`,
    ],
    pollTwin: true,
}
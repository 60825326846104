import { BoxGeometry, MeshStandardMaterial, Vector3 } from "three"
import { TwinEntity } from "../../../../generated/devGraphql"
import { Label3D } from "../../Label3D"
import { TwinEntityType } from "../../../../@types/TwinEntityType"
import { LabelMode } from "../../../../@types/LabelMode"
import {determineVisibility, getMenuHovered, getSelectionCriteria} from "../../utils/filterUtils"
import { useFilterContext } from "../../../../common/contexts/FilterContext"
import { useEventContext } from "../../../../common/contexts/EventContext"
import { SelectionOutlines } from "../../outlines/SelectionOutline"
import { Hitbox } from "../../Hitbox"
import { useMemo, useState } from "react"
import { Operator } from "./Operator"
import {Capacity} from "../../behaviours/Capacity";

type Props = {
    entity: TwinEntity,
    position: Vector3,
    lineage: TwinEntity[],
    mapDiv: HTMLDivElement;
	boxLength: number;
	boxWidth: number;
	operatorLength: number;
	gapRatio: number;
}

const material = new MeshStandardMaterial({ color: 'blue', transparent: true, opacity: 0, depthWrite: false })

export function Station({entity, position, lineage, mapDiv, boxLength, boxWidth, operatorLength, gapRatio } : Props) {

    const { filter } = useFilterContext();
	const { currentHoveredEntity } = useEventContext()
	const [hover, setHover] = useState(false)
	const [pressed, setPressed] = useState(false)

	const parent = lineage[lineage.length - 1]

	const { filtersExist, 
		    areaIsSelected, 
			parentIsSelected,
			siblingIsSelected 
		} = getSelectionCriteria(entity, parent, filter)

	const fullLineageIncludingThisEntity = [...lineage, entity]
	const menuHovered = getMenuHovered(fullLineageIncludingThisEntity, currentHoveredEntity)

	const showSolidOutline = areaIsSelected  || pressed;
	const showDashedOutline = (hover || menuHovered) && !areaIsSelected
	const showBehaviourBoxOutline = !(showSolidOutline || showDashedOutline)

	const geometry = useMemo(() => {
		const boxGeometry = new BoxGeometry(boxWidth, boxWidth, boxLength)
		boxGeometry.computeBoundingBox()
		return boxGeometry

	},[boxLength, boxWidth])

	const behavioursVisible = determineVisibility(entity, {
		filtersExist,
		areaIsSelected,
		parentIsSelected,
		siblingIsSelected,
		isTopLevelArea: true })

	const colorMode = filtersExist && !areaIsSelected && siblingIsSelected ? 'greys' : 'colors';
	
	return (
			<mesh
				geometry={geometry}
				material={material}
				position={position}
			>

				<Hitbox 
					entity={entity}
					geometry={ geometry } 
					setHover={setHover} 
					setPressed={setPressed}
					mapDiv={mapDiv} 
					lineage={lineage}
				/>
				<Capacity
					entity={entity}
					geometry={geometry}
					colorMode={colorMode}
					visible={behavioursVisible}
					showOutline={showBehaviourBoxOutline}
				/>
				<SelectionOutlines
					geometry={geometry}
					showDashedOutline={showDashedOutline}
					showSolidOutline={showSolidOutline}/>
				{(hover || menuHovered || areaIsSelected) &&
					<Label3D 
					entity={entity}
					entityType={TwinEntityType.STATION}
					labelMode={areaIsSelected ? LabelMode.FULL : LabelMode.BRIEF} 
					boundingBox={geometry.boundingBox!} 	 
					/>
				}
				
				{entity.children?.map((child, idx) => {
					return <Operator
						        key={idx}
								entity={child}
								position={new Vector3(0, 0, operatorLength * (1 + gapRatio) * (idx + 1/2) - boxLength/2)}
								lineage={[...lineage, entity]}
								mapDiv={mapDiv}
								boxLength={operatorLength}
								gapRatio={gapRatio}
								containerWidth={boxWidth}
							/>
				})}
			</mesh>
	);
   
}
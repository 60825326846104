/* 
* createApolloClient
* Creates Apollo Client to allow us to interact with GraphQL endpoints that allow the app to poll and subscribe.
*
*/
import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

const NO_JWT_LIST = new Set([
  'http://localhost:8080/graphql',
  'https://dev-graphql.twinlabs.ai/graphql', 
  'https://dev-graphql02.twinlabs.ai/graphql', 
  'https://dev-graphql03.twinlabs.ai/graphql'])

const createApolloClient = (httpUrl: string, wsUrl: string, liveAuthToken: string) => {

  if (!httpUrl) {
      throw new Error("liveGraphQLEndpoint not found")
  }

  if (!wsUrl) {
      throw new Error("liveGraphQLSubscriptions not found")
  }

  // Create HTTP link
  const httpLink = createHttpLink({
      uri: httpUrl,
  });

  // Create a WebSocket link:
  let connectionParams

  if (!NO_JWT_LIST.has(httpUrl)) {
    connectionParams = {
      'X-TL-Auth': liveAuthToken,
    }
  }

  const wsLink = new WebSocketLink({
      uri: wsUrl,
      options: {
        reconnect: true,
        connectionParams
      },
    });

  // Create an authentication middleware
  let authLink

  authLink = setContext((_, { headers }) => {
      let authLink
      // This is to handle the new development endpoints that don't utilise the JWT auth token
      // It is important to note that if incorrectly paass the X-TL-Auth header in the request you will get a CORS issue
      if (NO_JWT_LIST.has(httpUrl)) {
        authLink = {
          headers: {
            ...headers,
          },
        }
      } else {
        authLink = {
          headers: {
            ...headers,
            'X-TL-Auth': liveAuthToken || '', 
          },
        }
      }
      return authLink
  });


  // Use split to route queries and mutations over HTTP and subscriptions over WebSocket
  const link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      authLink.concat(httpLink) // Combine authLink with httpLink
    );

    return new ApolloClient({
      link: authLink.concat(link),
      cache: new InMemoryCache(),
  });
}

export default createApolloClient
import { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import axios from "axios"
import ReactECharts from 'echarts-for-react';

// Types
import { Message } from "../../@types/Message";
import { PredefinedPrompt } from "../../@types/PredefinedPrompt";

// Context
import { useSettingsContext } from "../../common/contexts/SettingsContext";
import { useUserContext } from "../../common/contexts/UserContext";

// Components
import TLPromptInput from "../elements/TLPromptInput"
import { ArrowUp12Filled } from "@fluentui/react-icons";



interface Props {
    className?: string
    apiEndpoint: string
    apiKey?: string
    triggerPrompt?: string
    addMessage: (message: Message) => void
    setAwaitingMessage: (value: boolean) => void
}

export type FormInputs = {
    message: string,
}

const formSchema = {
    message: yup.string().required(`Sorry, I didn't catch your message`),
}

const CopilotPrompt = ({ className, apiEndpoint, apiKey, triggerPrompt, addMessage, setAwaitingMessage }: Props) => {

    const { authCreds } = useUserContext()
    const { settings } = useSettingsContext()

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<FormInputs>({
        resolver: yupResolver(yup.object(formSchema)),
    })

    const clearPromptInput = () => {
        setValue('message', '')
    }

    useEffect(() => {

        async function sendMessage(triggerPrompt: string) {
            await askAIQuestion(apiEndpoint, triggerPrompt, apiKey)
        }

        if (triggerPrompt) {
            sendMessage(triggerPrompt)
        }

    }, [triggerPrompt])

    const askAIQuestion = async (apiEndpoint: string, userPrompt: string, apiKey?: string) => {

        setAwaitingMessage(true)

        if (apiEndpoint === '') {
            addMessage({ content: 'Sorry, I cannot answer. You need to ensure your API URL is correct.', response: true, prompts: [] })
            return
        }

        let headers = {
            'Content-Type': 'application/json',
        }

        if (apiKey) {
            headers = { ...headers, ...{ 'Authorization': `Bearer ${apiKey}` } }
        }

        axios.post(apiEndpoint, { question: userPrompt, organisation: settings?.organisation }, {
            headers: headers
        })
            .then(response => {

                let uiResponse = ''
                const chartData = null
                const chartWidth = null
                const suggestedPrompts: PredefinedPrompt[] = []

                // Handle Flowise response
                if (response.data.text) {
                    uiResponse = response.data.text
                    // Handle TwinLabs.ai Chat response
                } else if (response.data.response) {
                    uiResponse = response.data.response
                }

                addMessage(
                    {
                        content: uiResponse,
                        response: true,
                        chart: chartData && chartWidth ? <ReactECharts style={{ height: `400px`, width: chartWidth }} option={chartData} /> : undefined,
                        prompts: suggestedPrompts
                    }
                )
            })
            .catch(error => {
                addMessage(
                    {
                        content: `Something went wrong. Please try asking again.`,
                        prompts: [],
                        response: true,

                    })
                console.error('Error:', error.response ? error.response.data : error.message);
            })
            .finally(() => {
                setAwaitingMessage(false)
            })
    }

    const onSubmit: SubmitHandler<FormInputs> = async (formData) => {
        const userPrompt = formData.message
        // Do this immediately
        clearPromptInput()
        // Submit message
        submitMessage({ content: userPrompt, response: false, prompts: [] })
    }

    const submitMessage = async (message: Message) => {
        // Add user message to the conversation 
        addMessage(message)
        // Send message to AI
        await askAIQuestion(apiEndpoint, message.content, apiKey)
    }

    return (
        <div className={className}>

            <form onSubmit={handleSubmit(onSubmit)}>
                <TLPromptInput
                    id="txtMessage"
                    props={{
                        placeholder: "Type here. 0/500",
                        type: "text",
                        ...register("message")
                    }}
                />
                <button id="submit-button" type="submit">
                    <ArrowUp12Filled />
                </button>
            </form>
        </div>
    )
}

export default CopilotPrompt
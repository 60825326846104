/*
 * avgMetricData
 * Performs queries and returns averages for all Metrics
 * Returns all Raw Metric Data
 * 
*/
// Data
import { TSMetricData } from "../../../@types/Data/TSMetricData";

// Utils
import { avgHeadcount2 } from "./avgHeadcount2";
import { avgWindOrientation } from "./avgWindOrientation";
import { avgWindSpeed } from "./avgWindSpeed";

export const avgMetricData = async (
    startDateTime: string,
    endDateTime: string,
    step: string = "15m",
    organisation: string
): Promise<TSMetricData> => {
    try {

        let headcount
        let windOrientation
        let windSpeed

        try {
            headcount = await avgHeadcount2(endDateTime, step, organisation)
        } catch (error) {
            console.log(`Error fetching avgHeadcount2 time series data`)
        }

        try {
             windOrientation = await avgWindOrientation(startDateTime, endDateTime, step, organisation)
        } catch (error) {
            console.log(`Error fetching avgWindOrientation time series data`)
        }

        try {
            windSpeed = await avgWindSpeed(startDateTime, endDateTime, step, organisation)
       } catch (error) {
           console.log(`Error fetching avgWindSpeed time series data`)
       }

        return {
            headcount: headcount,
            windOrientation: windOrientation,
            windSpeed: windSpeed,
        }
    } catch (error) {
        console.error('Error fetching avgMetricData:', error);
        throw error;
    }
}
import Config from "../Config"

/*
 * resolveEndpoints
 * Used as a temporary measure to allow the app to switch between graphQL endpoints based on sub domain (defaults to Newport)
 * TODO: Needs to be removed once we have a single GraphQL API
 * 
*/
export const resolveEndpoints = (organisation: string) : {httpUrl: string, wsUrl: string} => {
  let httpUrl = 'https://dev-graphql.twinlabs.ai/graphql'
  let wsUrl = 'wss://dev-graphql.twinlabs.ai/graphql'

  if (Config.useLocalDgraph) {
    httpUrl = 'http://localhost:8080/graphql'
    wsUrl = 'ws://localhost:8080/graphql'
  } else {
    // Define GraphQL endpoints
  switch (organisation) {
    case "Newport Live":
      httpUrl = 'https://dev-graphql.twinlabs.ai/graphql'
      wsUrl = 'wss://dev-graphql.twinlabs.ai/graphql'
      break;
    case "Movico":
      httpUrl = 'https://dev-graphql02.twinlabs.ai/graphql'
      wsUrl = 'wss://dev-graphql02.twinlabs.ai/graphql'
      break;
    case "Howler":
      httpUrl = 'https://dev-graphql03.twinlabs.ai/graphql'
      wsUrl = 'wss://dev-graphql03.twinlabs.ai/graphql'
      break;
    default:
      // Do nothing
      break;
  }
  }

  return {
    httpUrl,
    wsUrl,
  }
}
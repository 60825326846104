import React, { useState } from "react"
import { Calendar24Filled, Calendar24Regular, CalendarWeekNumbers24Filled, CalendarWeekNumbers24Regular } from "@fluentui/react-icons"

// Types
import { TimeUnit } from "../@types/TimeUnit"

// Utils
import { stringToTimeUnit } from "../common/utils/stringToTimeUnit"

// Components
import CalendarMonthView from "./CalendarMonthView"
import CalendarWeekView from "./CalendarWeekView"
import Panel from "./Panel"
import TLSwitcher from "./elements/TLSwitcher"
import TLCard from "./elements/TLCard"
import { useFilterContext } from "../common/contexts/FilterContext"

import './Frame/Frame.css';
import CustomScrollbar from "./CustomScrollbar/CustomScrollbar"
interface Props {
    className?: string
}

const Calendar: React.FC<Props> = ({ className }) => {

    const { live } = useFilterContext();
    const [timeUnit, setTimeUnit] = useState<TimeUnit>(TimeUnit.WEEK)

    const handleTimeUnitSwitcher = (value: string) => {
        setTimeUnit(stringToTimeUnit(value))
    }

    return (
        <>
            {!live &&
                <div className={className}>
                     <div className="panel-header right-panel-header">
                        <TLCard className="tab-list" size="small">
                            <TLSwitcher
                                size="small"
                                selectedValue={timeUnit.toString()}
                                options={[
                                    {
                                        activeIcon: <CalendarWeekNumbers24Filled />,
                                        inactiveIcon: <CalendarWeekNumbers24Regular />,
                                        value: TimeUnit.WEEK.toString(),
                                        text: "Weekly"
                                    },
                                    {
                                        activeIcon: <Calendar24Filled />,
                                        inactiveIcon: <Calendar24Regular />,
                                        value: TimeUnit.MONTH.toString(),
                                        text: "Monthly"
                                    }
                                ]}
                                setValue={handleTimeUnitSwitcher}
                            />
                        </TLCard>
                    </div>
                    <CustomScrollbar right={-20} maxHeight={"calc(100vh - 210px)"} className="right-scrollable-container">
                        <Panel
                            id={'calendar'}
                            icon={<Calendar24Regular />}
                            title="Calendar"
                            defaultOpenItems={["calendar"]}
                            className="calendar-panel"
                        >
                            <>
                                {timeUnit === TimeUnit.WEEK ?
                                    <CalendarWeekView />
                                    : null}
                                {timeUnit === TimeUnit.MONTH ?
                                    <CalendarMonthView />
                                    : null}
                            </>
                        </Panel>
                    </CustomScrollbar>
                </div>
            }
        </>
    )
}

export default Calendar
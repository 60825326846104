/*
 * maxHeadcount
 * This is a wrapper for the timeSeriesRangeQuery function
 * Returns Max Headcount Hero Metric
 * 
*/

// Utils
import { timeSeriesRangeQuery } from "./timeSeriesRangeQuery";

export const maxHeadcount = async (
    startDateTime: string,
    endDateTime: string,
    step: string = "15m",
    organisation: string) => {

    let filterBy = `metric='countEntity'`

    if (organisation) {
        filterBy = filterBy + `,organisation='${organisation}'`
    }

    const query = `max by(metric) (max_over_time(metricvalue{${filterBy}}[${step}]))`

    return await timeSeriesRangeQuery(query, startDateTime, endDateTime, step)
}
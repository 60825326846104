import React from "react"
import {
    Card
} from "@fluentui/react-components";

interface Props {
    className?: string
    children?: JSX.Element
    size?: 'small' | 'medium' | 'large'
    padding?: string
    hovered?: boolean
    selected?: boolean
}

const TLCard: React.FC<Props> = ({className, children, padding, size, hovered, selected}) => {
    return (
            <Card
                className={className}
                style={{
                    padding: padding ? padding : "8px",
                    backgroundColor: 'rgb(255,255,255,0.40)',
                    backdropFilter: 'blur(6px)',
                    borderWidth: '2px',
                    borderRadius: '12px',
                    borderStyle: hovered ? 'dashed' : 'solid',
                    boxShadow: '0px 0px 11px 1px rgba(228, 255, 254, 0.55), 0px 3px 11px 0px rgba(0, 0, 0, 0.05)',
                    borderColor: hovered || selected ? 'rgba(228, 255, 254, 0.80)' : 'rgb(255,255,255,0)',
                }} 
                size={size}
            >
                {children}
            </Card>
    )
}

export default TLCard
import { BoxGeometry, MeshStandardMaterial, Vector3 } from "three"
import { TwinEntity } from "../../../../generated/devGraphql"
import { TwinEntityType } from "../../../../@types/TwinEntityType"
import { LabelMode } from "../../../../@types/LabelMode"
import {determineVisibility, getMenuHovered, getSelectionCriteria} from "../../utils/filterUtils"
import { useFilterContext } from "../../../../common/contexts/FilterContext"
import { useEventContext } from "../../../../common/contexts/EventContext"
import { SelectionOutlines } from "../../outlines/SelectionOutline"
import { Hitbox } from "../../Hitbox"
import {useMemo, useState} from "react"
import {Capacity} from "../../behaviours/Capacity";
import {OperatorLabel3D} from "./OperatorLabel3D";

type Props = {
    entity: TwinEntity,
    position: Vector3,
    lineage: TwinEntity[],
    mapDiv: HTMLDivElement;
	boxLength: number;
	containerWidth: number
	gapRatio: number;
}

const CONTAINER_WIDTH_RATIO = 0.7;

const material = new MeshStandardMaterial({ color: '#dfdfdf', opacity: 0.3 })

export function Operator({entity, position, lineage, mapDiv, boxLength, containerWidth } : Props) {


    const { filter } = useFilterContext();
	const { currentHoveredEntity } = useEventContext()
	const [hover, setHover] = useState(false)
	const [pressed, setPressed] = useState(false)

	const parent = lineage[lineage.length - 1]
	const { filtersExist, 
		    areaIsSelected, 
			parentIsSelected,
			siblingIsSelected 
		} = getSelectionCriteria(entity, parent, filter)

	const fullLineageIncludingThisEntity = [...lineage, entity]
	const menuHovered = getMenuHovered(fullLineageIncludingThisEntity, currentHoveredEntity)

	const showSolidOutline = areaIsSelected  || pressed;
	const showDashedOutline = (hover || menuHovered) && !areaIsSelected
	const showBehaviourBoxOutline = !(showSolidOutline || showDashedOutline)

	const behavioursVisible = determineVisibility(entity, {
		filtersExist,
		areaIsSelected,
		parentIsSelected,
		siblingIsSelected,
		isTopLevelArea: false })

	const colorMode = filtersExist && !areaIsSelected && siblingIsSelected ? 'greys' : 'colors';

	const boxWidth = CONTAINER_WIDTH_RATIO * containerWidth

	const {boxGeometry, behaviourBoxGeometry }  = useMemo(() => {
		const boxGeometry = new BoxGeometry(boxWidth, boxWidth, boxLength)
		boxGeometry.computeBoundingBox()

		const behaviourBoxGeometry = boxGeometry.clone()
		behaviourBoxGeometry.scale(1.1, 1.1, 1.1)
		behaviourBoxGeometry.computeBoundingBox()
		return { boxGeometry, behaviourBoxGeometry }

	},[boxLength, boxWidth])





	return (
			<mesh
				geometry={boxGeometry}
				material={material}
				position={position}>
				<Hitbox 
					entity={entity}
					geometry={ behaviourBoxGeometry }
					setHover={setHover} 
					setPressed={setPressed}
					mapDiv={mapDiv} 
					lineage={lineage}
				/>
				<Capacity
					entity={entity}
					geometry={behaviourBoxGeometry}
					colorMode={colorMode}
					visible={behavioursVisible}
					showOutline={showBehaviourBoxOutline}
				/>
				<SelectionOutlines
					geometry={behaviourBoxGeometry}
					showDashedOutline={showDashedOutline}
					showSolidOutline={showSolidOutline}/>
				<OperatorLabel3D
					entity={entity}
					entityType={TwinEntityType.OPERATOR}
					labelMode={areaIsSelected ? LabelMode.FULL : LabelMode.BRIEF} 
					boundingBox={boxGeometry.boundingBox!}
					visible={hover || menuHovered || areaIsSelected}
				/>
			</mesh>
	);
   
}
import React, { createContext, useCallback, useContext, useMemo, useReducer } from "react";

// Types
import { EntityHoverSelection } from "../../@types/EntityHoverSelection";

interface EventContextValue {
    currentHoveredEntity: EntityHoverSelection | null;
    previousHoveredEntity: EntityHoverSelection | null;
    setCurrentHoveredValue: (currentHoveredEntity: EntityHoverSelection | null) => void;
    setPreviousHoveredValue: (previousHoveredEntity: EntityHoverSelection | null) => void;
}

const initialState: EventContextValue = {
    currentHoveredEntity: null,
    previousHoveredEntity: null,
    setCurrentHoveredValue: () => {},
    setPreviousHoveredValue: () => {},
};

enum ActionType {
    setCurrentHoveredValue,
}

type Action = { type: ActionType.setCurrentHoveredValue; payload: EntityHoverSelection | null };

const reducer = (state: EventContextValue, action: Action): EventContextValue => {
    switch (action.type) {
        case ActionType.setCurrentHoveredValue:
            return {
                ...state,
                previousHoveredEntity: state.currentHoveredEntity,
                currentHoveredEntity: action.payload
            };
        default:
            return state;
    }
};

export const EventContext = createContext<EventContextValue>(initialState);

export const useEventContext = (): EventContextValue => {
    return useContext(EventContext);
};

interface EventContextProviderProps {
    children: React.ReactNode;
}

export const EventContextProvider: React.FC<EventContextProviderProps> = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const setCurrentHoveredValue = useCallback((currentHoveredEntity: EntityHoverSelection | null) => {
        dispatch({ type: ActionType.setCurrentHoveredValue, payload: currentHoveredEntity });
    }, []);

    const contextValue = useMemo(() => {
        return {
            ...state,
            setCurrentHoveredValue,
        };
    }, [state, setCurrentHoveredValue]);

    return (
        <EventContext.Provider value={contextValue}>
            {props.children}
        </EventContext.Provider>
    );
};

import React from "react"

// Types
import { DataMode } from "../@types/DataMode"

// Context
import { useDataContext } from "../common/contexts/DataContext"
import { useFilterContext } from "../common/contexts/FilterContext"
import { useSettingsContext } from "../common/contexts/SettingsContext"

// Utils
import { getDataValue } from "../common/utils/func-metrics/getDataValue"
import { getIndicator } from "../common/utils/getIndicator"
import { getIndicatorColor } from "../common/utils/getIndicatorColor"

// Components
import { PeopleError16Regular } from "@fluentui/react-icons"



interface Props {
    id: string // This could be an Entity ID or the Entity's bID (Business ID)
    capacity: number
    showOverCapacityWarning: boolean
    fontSize?: string | number // must be valid css
}

const AssetIndicator: React.FC<Props> = ({id, capacity, fontSize, showOverCapacityWarning }) => {

    const { live } = useFilterContext()
    const { data } = useDataContext()
    const { settings } = useSettingsContext()

    let indicator

    if (live) {
        // Using countEntity here for live. This might need to be selectable/interchangable if there are multiple hero metrics
        const countEntity = data.processed?.countEntity ?  data.processed?.countEntity : []
        const dataValue = getDataValue(
            live ? DataMode.LIVE : DataMode.TIME_SERIES,
            countEntity,
            id)
        let headcount = dataValue ? dataValue.value : 0
        indicator = getIndicator(headcount ? headcount : 0, capacity ? capacity : 0, settings?.rangeMapping, true)
    } else {
        // Using usage here. This might need to be selectable/interchangable if there are multiple hero metrics
        const metrics = data.processed?.usage ? data.processed?.usage : []
        const heroMetric = getDataValue(DataMode.TIME_SERIES, metrics, id)
        let usagePercentage = heroMetric ? heroMetric.value : 0
        indicator = {
            pc: usagePercentage,
            color: getIndicatorColor(usagePercentage, settings?.rangeMapping, true)
        } 
    }

    return (
        <div className="hero-indicator">
            {indicator && indicator.pc >= 0 ?
                <>
                {showOverCapacityWarning && indicator.pc > 100 ? <span className="over-capacity-indicator"><PeopleError16Regular /></span> : null}
                    <span className="hero-indicator-badge" style={{backgroundColor: indicator.color}}></span>
                    <span className="hero-indicator-label" style={{ fontSize: fontSize ?? "12px" }}>{indicator.pc.toFixed(1)}%</span>
                </>
            : null}
        </div>
    )
}

export default AssetIndicator
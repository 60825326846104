import React, { useRef, useEffect, useState, ReactNode } from 'react';
import './CustomScrollbar.css';

interface CustomScrollbarProps {
    children: ReactNode;
    className?: string;
    maxHeight?: number | string;
    right?: number;
}

const CustomScrollbar: React.FC<CustomScrollbarProps> = ({ children, className, maxHeight, right }) => {
    const contentRef = useRef<HTMLDivElement | null>(null);
    const thumbRef = useRef<HTMLDivElement | null>(null);
    const initialYRef = useRef(0);
    const scrollStartRef = useRef(0);
    const [thumbHeight, setThumbHeight] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    const updateThumbHeight = () => {
        if (contentRef.current) {
            const contentHeight = contentRef.current.scrollHeight;
            const containerHeight = contentRef.current.clientHeight;

            if (contentHeight > containerHeight) {
                const calculatedThumbHeight = (containerHeight / contentHeight) * containerHeight;
                setThumbHeight(calculatedThumbHeight);
            } else {
                setThumbHeight(0);
            }
        }
    };

    const updateThumbPosition = () => {
        if (contentRef.current && thumbRef.current) {
            const scrollTop = contentRef.current.scrollTop;
            const containerHeight = contentRef.current.clientHeight;
            const contentHeight = contentRef.current.scrollHeight;
            const thumbPosition = (scrollTop / contentHeight) * containerHeight;
            thumbRef.current.style.top = `${thumbPosition}px`;
        }
    };

    const handleScroll = () => {
        updateThumbPosition();
    };

    const handleThumbDrag = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(true);
        initialYRef.current = e.clientY;
        scrollStartRef.current = contentRef.current ? contentRef.current.scrollTop : 0;

        const onMouseMove = (moveEvent: MouseEvent) => {
            if (contentRef.current && thumbRef.current) {
                const deltaY = moveEvent.clientY - initialYRef.current;
                const containerHeight = contentRef.current.clientHeight;
                const contentHeight = contentRef.current.scrollHeight;
                const scrollAmount = (deltaY / containerHeight) * contentHeight;
                contentRef.current.scrollTop = scrollStartRef.current + scrollAmount;

                requestAnimationFrame(updateThumbPosition);
            }
        };

        const onMouseUp = () => {
            setIsDragging(false);
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
    };

    const handleTrackClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (thumbRef.current && contentRef.current) {
            const thumbRect = thumbRef.current.getBoundingClientRect();

            // Check if click is outside the thumb
            if (e.clientY < thumbRect.top || e.clientY > thumbRect.bottom) {
                const containerRect = contentRef.current.getBoundingClientRect();
                const clickY = e.clientY - containerRect.top;
                const containerHeight = contentRef.current.clientHeight;
                const contentHeight = contentRef.current.scrollHeight;

                const scrollPosition = (clickY / containerHeight) * contentHeight;
                contentRef.current.scrollTop = scrollPosition;

                requestAnimationFrame(updateThumbPosition);
            }
        }
    };

    const handleWheel = (e: React.WheelEvent) => {
        if (contentRef.current) {
            contentRef.current.scrollTop += e.deltaY;
            updateThumbPosition();
        }
    };

    useEffect(() => {
        updateThumbHeight();

        const handleResize = () => {
            updateThumbHeight();
        };

        window.addEventListener('resize', handleResize);

        const mutationObserver = new MutationObserver(() => {
            setTimeout(updateThumbHeight, 50);
        });

        if (contentRef.current) {
            mutationObserver.observe(contentRef.current, {
                childList: true,
                subtree: true,
                attributes: true,
            });
        }

        return () => {
            if (contentRef.current) {
                mutationObserver.disconnect();
            }
            window.removeEventListener('resize', handleResize);
        };
    }, [children]);

    return (
        <div className={`scroll-container ${className || ''}`} style={{ position: 'relative', height: '100%', maxHeight }}>
            <div
                className="scroll-content"
                ref={contentRef}
                onScroll={handleScroll}
                style={{ maxHeight }}
            >
                {children}
            </div>
            {thumbHeight > 0 && (
                <div
                    className="custom-scrollbar"
                    onClick={handleTrackClick} 
                    onWheel={handleWheel} 
                    style={{
                        right
                    }}
                >
                    <div
                        className={`custom-scrollbar-thumb ${isDragging ? 'active' : ''}`}
                        ref={thumbRef}
                        onMouseDown={handleThumbDrag}
                        style={{
                            height: `${thumbHeight}px`,
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default CustomScrollbar;

import React, { useState } from 'react'
import { ChevronLeft16Filled, ChevronRight16Filled } from "@fluentui/react-icons"
import { DateTime } from 'luxon'

// Types
import { WeekRange } from '../@types/WeekRange'

// Context
import { useSettingsContext } from '../common/contexts/SettingsContext'

// Utils
import { getWeekRange } from '../common/utils/getWeekRange'
import { getOrdinalSuffix } from '../common/utils/getOrdinalSuffix'



interface Props {
    className?: string
    weekViewDateTime: string
    setCalendarDateTime: (startDateTimeISO: string, finishDateTimeISO: string) => void
}

const WeekStepper: React.FC<Props> = ({className, weekViewDateTime, setCalendarDateTime}) => {

  const { settings } = useSettingsContext()
  const [currentDay, setCurrentDay] = useState<string>(weekViewDateTime)
  const [weekRange, setWeekRange] = useState<WeekRange>(getWeekRange(weekViewDateTime))
  const [ timeZone ] = useState<string>(settings?.timeZone ? settings.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone)

  const goBackOneWeek = () => {
    const prevWeek = DateTime.fromISO(currentDay).minus({days: 7}).setZone(timeZone)
    if (prevWeek.isValid) {
      let prevWeekDateTimeISO = prevWeek.toISO({includeOffset: true})
      setCurrentDay(prevWeekDateTimeISO)
      const weekRange = getWeekRange(prevWeekDateTimeISO)
      setWeekRange(weekRange)
      setCalendarDateTime(weekRange.startDate, weekRange.endDate)
    }
  }

  const goForwardOneWeek = () => {
    const nextWeek = DateTime.fromISO(currentDay).plus({days: 7}).setZone(timeZone)
    if (nextWeek.isValid) {
      let nextWeekDateTimeISO = nextWeek.toISO({includeOffset: true})
      setCurrentDay(nextWeekDateTimeISO)
      const weekRange = getWeekRange(nextWeekDateTimeISO)
      setWeekRange(weekRange)
      setCalendarDateTime(weekRange.startDate, weekRange.endDate)
    }
  }

  const formatWeekRange = () => {
    let displayString
    if (weekRange.startDate && weekRange.endDate) {
      const startDay = DateTime.fromISO(weekRange.startDate).day
      const startMonth = DateTime.fromISO(weekRange.startDate).monthLong
      const finishDay = DateTime.fromISO(weekRange.endDate).day
      const finishMonth = DateTime.fromISO(weekRange.endDate).monthLong
      const year = DateTime.fromISO(weekRange.endDate).year
      displayString = `${startDay}${getOrdinalSuffix(startDay)} ${startMonth === finishMonth ? `` : startMonth} ${finishDay ? `- ${finishDay}${getOrdinalSuffix(finishDay)}` : ``} ${finishMonth} ${year}`
    }
    return displayString
  }

  return (
    <div className={className}>
        <div className="calendar-stepper">
            <button onClick={goBackOneWeek}><ChevronLeft16Filled /></button>
            <p>{formatWeekRange()}</p>
            <button onClick={goForwardOneWeek}><ChevronRight16Filled /></button>
        </div>
    </div>
  )
}

export default WeekStepper

import { useDebouncedCallback } from 'use-debounce';

// Context
import { useEventContext } from '../../common/contexts/EventContext'

// Components
import AssetAccordionItem from './AssetAccordionItem';
import { useState } from 'react';

const withContext = (Component: any) => {
    /* @ts-ignore */
    return (props) => {

        const { currentHoveredEntity, previousHoveredEntity, setCurrentHoveredValue } = useEventContext()
    
        const handleMouseEnter = useDebouncedCallback((id: string, type: "ASSET" | "ENTITY") => {
            setCurrentHoveredValue({ id: id, type: type })
        }, 0)
        
        const handleMouseLeave = useDebouncedCallback(() => {
            setCurrentHoveredValue(null)
        }, 0)

        const [ firstHeaderRendered, setFirstHeaderRendered ] = useState()

        return (
            <Component
                {...props}
                currentHoveredEntity={currentHoveredEntity}
                previousHoveredEntity={previousHoveredEntity}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                live={props.live}
                firstHeaderRendered={firstHeaderRendered}
                setFirstHeaderRendered={setFirstHeaderRendered}
            />
        );
    };
};

const AccordionItemWithContext = withContext(AssetAccordionItem);

export default AccordionItemWithContext;
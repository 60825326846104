// Context
import { useUserContext } from "../common/contexts/UserContext"

// Components
import Copilot from "./Copilot/Copilot"
import { Dismiss16Regular } from "@fluentui/react-icons"
import { ReactComponent as CopilotColorIcon } from "../common/svg/copilot-color-icon.svg"
import Subtitle from "./Typography/Subtitle"

interface Props {
    className?: string
    setSupportCopilotVisible: (value: boolean) => void
}

const SupportCopilot = ({ className, setSupportCopilotVisible }: Props) => {
    const { user } = useUserContext()

    const headerElement = (
        <div className="flex p-[12px] justify-between">
            <div className="flex gap-1">
                <div className="avatar mt-[3px]">
                    <CopilotColorIcon />
                </div>
                <Subtitle className="m-auto">Support Copilot</Subtitle>
            </div>
            <button className="close-button" onClick={() => setSupportCopilotVisible(false)}>
                <Dismiss16Regular />
            </button>
        </div>
    )

    return (
        <div className={className}>
            <Copilot
                className="copilot"
                apiEndpoint="https://flow.twinlabs.ai/api/v1/prediction/27acef9a-1921-42e5-8e8b-5fc958a52d57"
                apiKey="h6rM8kEawj7pvMaWieh1CfdEOyXRJGnDQhd3OVSwy5Q"
                openingMessage={{
                    content: `Hello ${user?.firstName}, how can I help you today?`,
                    response: true,
                    prompts: [],
                }}
                headerElement={headerElement}
            />
        </div>
    )
}

export default SupportCopilot
